.login-page .card-login .card-header img {
  padding-left: 75px;
  padding-right: 75px;
  padding-top: 50px;
}

.login-page .card-login .card-body {
  padding-top: 60px;
}


.register-page .card-register .card-header {
  padding: 0;
  padding-top: 25px;
  padding-left: 15px;
}

.register-page .card-register .card-header .card-title {
  text-transform: none;
  color: whitesmoke;
  font-size: 2.5em;
  font-weight: 450;
}

.register-organization-page .content {
  padding-top: 50px;
}

.sidebar,
.off-canvas-sidebar {
  background: #314476;
  background: -webkit-linear-gradient(0deg, #314476 0%, #23314f 100%);
  background: -o-linear-gradient(0deg, #314476 0%, #23314f 100%);
  background: -moz-linear-gradient(0deg, #314476 0%, #23314f 100%);
  background: linear-gradient(0deg, #314476 0%, #23314f 100%);
}

.sidebar:before,
.off-canvas-sidebar:before {
  border-bottom-color: #23314f;
}

.sidebar .logo {
  padding: 10px 50px 10px 50px;
}

.sidebar-mini .sidebar .logo {
  display: none;
}

.nav-open .sidebar .logo {
  padding: 10px 50px 10px 50px;
  display: block;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
}

ul.list-chain {
  position: relative;
  list-style: none;
  padding: 0;

  &:before {
    content: "";
    position: absolute;
    left: 24px;
    width: 3px;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.05);
  }

  & > li {
    position: relative;
    margin-bottom: 20px;

    .list-chain-icon {
      position: absolute;
      width: 50px;
      height: 50px;
      background-color: #fd5d93;
      border-radius: 50%;
      top: 16px;
    }

    .list-chain-content {
      position: relative;
      margin-left: 80px;
      background-color: rgba(255, 255, 255, 0.05);
      min-height: 100px;
      padding: 20px;
      border-radius: 0.2857rem;

      &:before {
        content: " ";
        position: absolute;
        top: 26px;
        right: -15px;
        display: inline-block;
        border-color: transparent rgba(255, 255, 255, 0.05);
        border-style: solid;
        border-width: 15px 0 15px 15px;
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
      }

      p {
        font-size: 0.75rem;
        font-weight: 400;
      }

      .status {
        font-size: 0.75rem;
        font-weight: 400;
        margin-top: 10px;
      }
    }
  }
}

.card-navigation {
  padding-left: 60px;
  padding-right: 60px;

  .card-navigation-left,
  .card-navigation-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 80px;
    padding: 15px;

    display: flex;
    align-items: center;
    cursor: pointer;

    color: #fff;
    font-size: 24px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    &:hover:before {
      opacity: 1;
    }
  }

  .card-navigation-left {
    left: 0;
    justify-content: flex-start;

    &:before {
      background: linear-gradient(
                      90deg,
                      rgba(0, 0, 0, 0.3) 0%,
                      rgba(0, 0, 0, 0) 100%
      );
    }
  }

  .card-navigation-right {
    right: 0;
    justify-content: flex-end;

    &:before {
      background: linear-gradient(
                      270deg,
                      rgba(0, 0, 0, 0.3) 0%,
                      rgba(0, 0, 0, 0) 100%
      );
    }
  }
}

.animate-emphasize {
  animation: emphasize 0.2s linear;
}

@keyframes emphasize {
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.3);
  }
  100% {
    filter: brightness(1);
  }
}

.input-group, .form-group {
  .validation-message .dropdown-menu {
    visibility: hidden;
    opacity: 0;

    z-index: 1;
    pointer-events: none;
  }

  &:focus-within .validation-message .dropdown-menu,
  &:hover .validation-message .dropdown-menu {
    visibility: visible;
    opacity: 1;
  }
}

.flex-even {
  flex: 1;
}

.dropdown-menu.max-height {
  max-height: 380px;
}

.dropdown-menu.scrollable-auto {
  overflow: auto;
}

.dropdown-menu.scrollable-hidden {
  overflow: hidden;
}

.break-space {
    white-space: pre-line;
}